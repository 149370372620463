import React, { useEffect, useState } from 'react';
import { DoctForm } from '@docthub.frontend/app';
import { DoctButton, DoctTypography } from 'doct-core';
import { useDispatch, useSelector } from 'react-redux';
import { EventRegistrationLayoutFooter } from '../../../../../layout';
import { AttendeeForm } from '../../../../../shared';
import {
  saveAttendeeDetails,
  selectAttendeeDetails,
  selectOnGoingPriceCategory,
  selectSelectedTickets,
  setActiveStep,
} from '../complementaryRegistration.slice';
import { FORM_NAMES_STEPS } from '../../../../../shared/ui/AttendeeForm/attendeeForm.constants';
import { WORK_STATUS_TYPE } from '../../../../../helper/enums/eventStatus';

const attendeeDefaultValue = {
  country: {
    label: 'India',
    value: 'India',
  },
  mobileCountryCode: {
    label: '+91',
  },
  whatsappCountryCode: {
    label: '+91',
  },
  [FORM_NAMES_STEPS.workStatus]: WORK_STATUS_TYPE.WorkingProfessional,
};

export default function AttendeeDetails() {
  const selectedTickets = useSelector(selectSelectedTickets);
  const onGoingPriceCategory = useSelector(selectOnGoingPriceCategory);
  const attendeeDetails = useSelector(selectAttendeeDetails);

  const [, setActivePriceTypeOfSelectedTicket] = useState({});
  const { handleSubmit, control, errors, reset, touched, watch, setValue, clearErrors, register } =
    DoctForm({
      mode: 'onChange',
      defaultValues: attendeeDefaultValue,
      shouldUnregister: false,
    });

  useEffect(() => {
    reset({ ...attendeeDefaultValue, ...attendeeDetails });
  }, []);

  useEffect(() => {
    const findOnGoingPriceOfSelectedTicket = selectedTickets?.prices?.find(({ category }) => {
      return category == onGoingPriceCategory;
    });

    setActivePriceTypeOfSelectedTicket(findOnGoingPriceOfSelectedTicket);
  }, [onGoingPriceCategory]);

  const handleFormSubmit = handleSubmit((values) => {
    values['specialtyIds'] = [values?.specialtyIds?.value];
    if (values.workStatus === WORK_STATUS_TYPE.Student) {
      delete values?.currentDesignation;
      delete values?.currentOrganization;
    }
    dispatch(saveAttendeeDetails(values));
    dispatch(setActiveStep(2));
  });

  const dispatch = useDispatch();

  return (
    <>
      <DoctTypography variant="h6" className="mb-3 mt-4">
        Fill attendee details
      </DoctTypography>
      <div className="selection-panel-container">
        <div className="bg-grey-100 registration-form-box border-radius box-shadow">
          <DoctTypography variant="subtitle2" className="my-0">
            {selectedTickets?.name} Attendee
          </DoctTypography>
          {/* <DoctTypography variant="textLabel2" className="my-0 text-grey-600 text-uppercase">
            {activePriceTypeOfSelectedTicket?.category}: {selectedTickets?.currency}{' '}
            {activePriceTypeOfSelectedTicket?.amount}
          </DoctTypography> */}
          <div className="line-divider bg-grey-200 mt-3 registration-form-gap-bottom"></div>
          <form onSubmit={handleFormSubmit}>
            <AttendeeForm
              control={control}
              errors={errors}
              touched={touched}
              watch={watch}
              clearErrors={clearErrors}
              setValue={setValue}
              savedValue={attendeeDetails}
              register={register}
            />
          </form>
        </div>
      </div>

      <EventRegistrationLayoutFooter>
        <DoctButton
          variant="outline"
          text="Back"
          className="ml-auto mr-12px"
          onButtonClickHandler={() => {
            dispatch(setActiveStep(0));
          }}
        />
        <DoctButton
          icon="right"
          iconPosition="right"
          text="Continue"
          onButtonClickHandler={() => {
            if (watch(FORM_NAMES_STEPS.workStatus) === WORK_STATUS_TYPE.Student) {
              clearErrors([
                FORM_NAMES_STEPS.currentOrganization,
                FORM_NAMES_STEPS.currentDesignation,
              ]);
            }
            handleFormSubmit();
          }}
        />
      </EventRegistrationLayoutFooter>
    </>
  );
}
