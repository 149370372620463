import { DoctForm } from '@docthub.frontend/app';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCourseFormValues,
  selectIdStoreAtTime,
  selectRawBrochureUrl,
  selectRawFormValues,
  setAffiliation,
  setBrochureUrl,
  setEditTimeApplyType,
  setFaculties,
  setIdStoreAtTime,
  setInstituteCourseFormValues,
  setIntakes,
  setRawFormValues,
  setSelectMinimumEducation,
} from '../ManageCourseSlice';
import { useEffect, useState } from 'react';
import {
  useAddCourseMutation,
  useEditCourseMutation,
  useGetCourseDetalsByIdQuery,
} from '../services/manageCourse.services';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import * as ROUTES from '../../../../../routes/constant';
import useResetOnEdit from '../utils/hooks/useResetOnEdit';
import { serialize } from 'object-to-formdata';
import { userSelector } from '../../../../../components';
import useFocus from '../../../../../hooks/useFocus';

const useFormCourses = () => {
  const location = useLocation();
  const { pathname } = location;
  const courseDetails = useSelector(selectCourseFormValues);
  const rawFormValues = useSelector(selectRawFormValues);
  const user = useSelector(userSelector);

  const [
    addCourse,
    {
      isLoading: isAdding,
      isError: isAddedFailed,
      isSuccess: isSuccessFullyAdded,
      error: addTimeErrorMessage,
    },
  ] = useAddCourseMutation();
  const [
    editCourse,
    {
      isLoading: isUpdatingCourse,
      isError: isUpdatedFailed,
      isSuccess: isSuccessFullUpdated,
      error: updateTimeErrorMessage,
    },
  ] = useEditCourseMutation();
  const selectBrochure = useSelector(selectRawBrochureUrl);
  const idFromRoute = useSelector(selectIdStoreAtTime);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [intakesArr, setIntakesArr] = useState([]);
  const [facultiesArr, setFacultiesArr] = useState([]);
  const [minimumEducationArr, setMinimumEducationArr] = useState([]);
  const [minimumEducationIdArr, setMinimumEducationIdArr] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [currentApplyType, setCurrentApplyType] = useState(2);
  const [affiliations, setAffiliations] = useState([]);
  const courseId = pathname.replace(/[^0-9]/g, '');

  const { data: courseDataById, isFetching: courseIsFetching } = useGetCourseDetalsByIdQuery(
    { id: courseId },
    { refetchOnMountOrArgChange: true, skip: !courseId },
  );
  const { resetFromObjOnEdit } = useResetOnEdit({
    courseDataById,
    setIntakesArr,
    setFacultiesArr,
    setMinimumEducationArr,
    setCurrentApplyType,
    setAffiliations,
  });

  const [uploadedBrochure, setUploadedBrochure] = useState(
    selectBrochure?.length ? selectBrochure : [],
  );
  const [errorMessage, setErrorMessage] = useState();

  const [resetBrochureUrl, setResetBrochureUrl] = useState();
  const [deleteBrochureUrl, setDeleteBrochureUrl] = useState(false);
  const [isEditTimeEmailValue, setIsEditTimeEmailValue] = useState(false);
  const [defaultDisciplineValue, setDefaultDisciplineValue] = useState();
  const [storeStatus, setStoreStatus] = useState('');
  const [fromUpperBtnClicked, setFromUpperBtnClicked] = useState(false);

  const defaultValues = {
    affiliation: [],
  };

  const { handleSubmit, control, errors, reset, watch, setValue, register } = DoctForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  // below useEffect is for resetting the form on pressing back button

  useEffect(() => {
    if (!user?.tenant) {
      return;
    }
    if (Object.keys(rawFormValues || {}).length) {
      setDefaultDisciplineValue(rawFormValues?.disciplineId?.label);
      setIsEditTimeEmailValue(true);
      dispatch(setEditTimeApplyType(rawFormValues.applyType));
      setFromUpperBtnClicked(false);
      reset({ email: user?.tenant?.email, ...rawFormValues });
    } else {
      reset({ email: user?.tenant?.email });
    }
  }, [rawFormValues, user?.tenant?.email]);

  // below useEffect is for resetting the form on Edit

  useEffect(() => {
    if (!idFromRoute) {
      if (Object.keys(resetFromObjOnEdit)?.length) {
        setCurrentApplyType(resetFromObjOnEdit?.applyType);
        dispatch(setEditTimeApplyType(resetFromObjOnEdit.applyType));
        if (resetFromObjOnEdit.viewContactMobileNumber) {
          resetFromObjOnEdit.code = resetFromObjOnEdit.viewContactMobileNumber.split('-')[0];
          resetFromObjOnEdit.viewContactMobileNumber =
            resetFromObjOnEdit.viewContactMobileNumber.split('-')[1];
        }
        if (resetFromObjOnEdit.telephoneNumber) {
          resetFromObjOnEdit.telephoneNumber = resetFromObjOnEdit.telephoneNumber.split('-')[1];
        }
        if (resetFromObjOnEdit?.brochure) {
          setResetBrochureUrl(resetFromObjOnEdit?.brochure);
        } else {
          setResetBrochureUrl(null);
        }
        if (resetFromObjOnEdit.mobileNumber) {
          resetFromObjOnEdit.mobileNumber = resetFromObjOnEdit.mobileNumber.split('-')[1];
        }
        setDefaultDisciplineValue(resetFromObjOnEdit?.specialty?.discipline);
        setIsEditTimeEmailValue(true);
        reset(resetFromObjOnEdit);
      }
    }
  }, [courseDataById, idFromRoute]);

  // Below function is responsible for transforming the data in order to send it to the api

  const transformCourseValues = (values) => {
    const affiliationIds = [];
    const affiliationData = [];

    affiliations?.forEach((affiliation) => {
      affiliationIds.push(affiliation?.id);
    });

    affiliations?.map((affiliation) => {
      affiliationData.push(affiliation?.label);
    });

    const qualificationIds = [];

    minimumEducationArr?.forEach((qualification) => {
      qualificationIds.push(qualification?.id);
    });

    const transformedValues = { ...values };
    transformedValues['courseTitle'] = transformedValues?.courseTitleId?.label;
    transformedValues['courseTitleId'] = transformedValues?.courseTitleId?.id;
    transformedValues['courseTypeId'] = transformedValues?.courseTypeId?.id;
    transformedValues['disciplineId'] = transformedValues?.disciplineId?.id;
    transformedValues['durationType'] = transformedValues?.durationType?.label;
    transformedValues['accomodation'] = transformedValues?.accomodation?.value;
    transformedValues['feesType'] = transformedValues?.feesType?.label;
    transformedValues['specialtyId'] = transformedValues?.specialtyId?.id;
    transformedValues['intakes'] = intakesArr;
    transformedValues['qualificationIds'] = qualificationIds;
    transformedValues['faculties'] = facultiesArr;
    transformedValues['affiliationIds'] = affiliationIds;
    transformedValues['affiliation'] = affiliationData;
    transformedValues['applyType'] = currentApplyType;
    transformedValues['brochureUrl'] = uploadedBrochure?.length ? uploadedBrochure[0] : [];
    transformedValues['code'] = '+91';

    return transformedValues;
  };

  const onFocus = useFocus(errors);

  useEffect(() => {
    onFocus();
  }, [errors]);

  useEffect(() => {
    if (addTimeErrorMessage) {
      setErrorMessage(addTimeErrorMessage.data?.Title);
    } else if (updateTimeErrorMessage) {
      setErrorMessage(updateTimeErrorMessage.data?.Title);
    }
  }, [addTimeErrorMessage, updateTimeErrorMessage, errorMessage]);

  useEffect(() => {
    if (errorMessage) {
      if (errorMessage != 'Course is already exist in this organization') {
        if (isAddedFailed || isUpdatedFailed) {
          navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSTITUTE}/${ROUTES.ERROR_IN_COURSE_ACTION}`);
        }
      }
    }
    if (isSuccessFullyAdded || isSuccessFullUpdated) {
      dispatch(setRawFormValues(null));
      dispatch(setInstituteCourseFormValues({}));
      dispatch(setAffiliation(null));
      dispatch(setIntakes(null));
      dispatch(setSelectMinimumEducation(null));
      dispatch(setFaculties(null));
      dispatch(setBrochureUrl(null));
      dispatch(setEditTimeApplyType(null));
      dispatch(setIdStoreAtTime(null));
      setIsEditTimeEmailValue(false);
      setFromUpperBtnClicked(false);
      if (storeStatus === 'ActivationRequested') {
        navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSTITUTE}/${ROUTES.COURSE_ACTION_SUCCSSFUL}`);
      } else {
        navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSTITUTE}/${ROUTES.INSTITUTE_COURSES}`);
      }
    }
  }, [isSuccessFullyAdded, isSuccessFullUpdated, errorMessage, isAddedFailed, isUpdatedFailed]);

  // below code is responsible for transforming and adding the values entered by the user in state.
  const handleCourseFormSubmit = handleSubmit((values, param) => {
    dispatch(setAffiliation(affiliations));
    dispatch(setIntakes(intakesArr));
    dispatch(setSelectMinimumEducation(minimumEducationArr));
    dispatch(setFaculties([...new Set(facultiesArr)]));
    dispatch(setBrochureUrl(uploadedBrochure));

    const affiliationIds = [];

    affiliations?.forEach((affiliation) => {
      affiliationIds.push(affiliation?.id);
    });

    const qualificationIds = [];

    minimumEducationArr?.forEach((qualification) => {
      qualificationIds.push(qualification?.id);
    });

    const rawFormData = { ...values };
    rawFormData['affiliation'] = affiliations;
    rawFormData['qualificationIds'] = qualificationIds;
    rawFormData['intakes'] = intakesArr;
    rawFormData['selectMinimumEducation'] = minimumEducationArr;
    rawFormData['faculties'] = facultiesArr;
    rawFormData['brochureUrl'] = uploadedBrochure;

    if (resetBrochureUrl) {
      values.brochureFileName = resetBrochureUrl;
    }
    if (deleteBrochureUrl) {
      values.deleteBrochure = true;
    }
    dispatch(setIdStoreAtTime(courseId));
    if (param) {
      if (Object.keys(courseDetails)?.length && fromUpperBtnClicked) {
        const status = 'Draft';
        setStoreStatus(status);
        handleApiCall(courseId, courseDetails, status);
      } else {
        const status = 'Draft';
        setStoreStatus(status);
        const transformedValues = transformCourseValues(values);
        handleApiCall(courseId, transformedValues, status);
      }
    } else {
      dispatch(setRawFormValues(rawFormData));
      dispatch(setInstituteCourseFormValues(transformCourseValues(values)));
      setIsPreview(true);
    }
  });

  const handleOnPreviewApproved = () => {
    const status = 'ActivationRequested';
    setStoreStatus(status);
    handleApiCall(courseId, courseDetails, status);
  };

  const handleApiCall = (courseId, values, status) => {
    const formData = serialize(
      {
        ...values,
        status,
      },
      { indices: true, dotsForObjectNotation: true, nullsAsUndefineds: true },
    );
    if (!courseId) {
      addCourse({ formValues: formData }).then(() => {});
    } else {
      editCourse({ formValues: formData, id: courseId }).then(() => {});
    }
    setErrorMessage();
  };

  return {
    control,
    errors,
    watch,
    reset,
    handleCourseFormSubmit,
    isPreview,
    courseDetails,
    intakesArr,
    setIntakesArr,
    facultiesArr,
    setFacultiesArr,
    minimumEducationArr,
    setMinimumEducationArr,
    handleOnPreviewApproved,
    setMinimumEducationIdArr,
    minimumEducationIdArr,
    setIsPreview,
    setCurrentApplyType,
    affiliations,
    setAffiliations,
    currentApplyType,
    setValue,
    uploadedBrochure,
    setUploadedBrochure,
    isAdding,
    isUpdatingCourse,
    errorMessage,
    setResetBrochureUrl,
    resetBrochureUrl,
    setDeleteBrochureUrl,
    courseIsFetching,
    rawFormValues,
    isEditTimeEmailValue,
    defaultDisciplineValue,
    setFromUpperBtnClicked,
    setIsEditTimeEmailValue,
    register,
  };
};

export default useFormCourses;
